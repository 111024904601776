<template>
  <div>
    <v-card outlined v-for="item in data" :key="item.id" class="my-2 pa-3" hover>
      <v-snackbar v-model="snackbar" :color="type" bottom left>
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
      <v-card-title>
        {{ item.title }}
        <v-spacer></v-spacer>
        <v-icon color="primary" @click="$emit('edit', item.id)">mdi-pencil</v-icon>
        <v-icon color="error" @click="$emit('delete', item.id)">mdi-trash-can</v-icon>
      </v-card-title>
      <v-card-text>
        <ListEmail :emails="item.emails" />
        <v-divider></v-divider>
        <div>
          <div>Created {{ item.createdAt.toDate() | fromNow }}</div>
          <div>Updated {{ item.updatedAt.toDate() | fromNow }}</div>
        </div>
        <div class="mt-2">
          <copy-text
            @copy="onCopySuccess"
            :text="item.id"
            :btnAttrs="{ small: true }"
            btnText="Copy ID"
          >
            <span></span>
          </copy-text>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Email from '../../../services/email';
import Moment from 'moment';
import CopyText from '../../Drive/CopyText.vue';
import ListEmail from './ListEmail.vue';
const email = new Email();
export default {
  components: {
    CopyText,
    ListEmail
  },
  data() {
    return {
      data: [],
      success: '',
      snackbar: false,
      message: '',
      type: 'success'
    };
  },
  created() {
    this.getData();
  },
  filters: {
    fromNow(date) {
      return Moment(date).fromNow();
    }
  },
  methods: {
    cmp(a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    sort(arr) {
      const res = [...arr];
      res.sort(this.cmp);
      return res;
    },
    onCopySuccess() {
      this.snackbar = true;
      this.message = 'ID copied to clipboard';
      this.type = 'primary';
      setTimeout(() => {
        this.snackbar = false;
        this.message = '';
      }, 3000);
    },
    getData() {
      email.listMailingList({}, res => {
        const data = [];
        res.forEach(item => {
          data.push({ ...item.data(), id: item.id });
        });
        this.data = data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
</style>

