import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.data),function(item){return _c(VCard,{key:item.id,staticClass:"my-2 pa-3",attrs:{"outlined":"","hover":""}},[_c(VSnackbar,{attrs:{"color":_vm.type,"bottom":"","left":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}],null,true),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c(VCardTitle,[_vm._v(" "+_vm._s(item.title)+" "),_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('edit', item.id)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_vm._v("mdi-trash-can")])],1),_c(VCardText,[_c('ListEmail',{attrs:{"emails":item.emails}}),_c(VDivider),_c('div',[_c('div',[_vm._v("Created "+_vm._s(_vm._f("fromNow")(item.createdAt.toDate())))]),_c('div',[_vm._v("Updated "+_vm._s(_vm._f("fromNow")(item.updatedAt.toDate())))])]),_c('div',{staticClass:"mt-2"},[_c('copy-text',{attrs:{"text":item.id,"btnAttrs":{ small: true },"btnText":"Copy ID"},on:{"copy":_vm.onCopySuccess}},[_c('span')])],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }