import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.rules.requiredRule,"label":"List Name"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('h4',{staticClass:"text-h6"},[_vm._v("Select Recipients")]),(_vm.users)?_c('SelectList',{ref:"emailSelect",attrs:{"search":"","sort":"","items":_vm.users,"itemText":"value"},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var selected = ref.selected;
return [_c('span',{staticClass:"info--text"},[_c(VIcon,{staticClass:"mr-1",attrs:{"color":"info"}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(selected)+" "+_vm._s(selected > 1 ? 'Emails' : 'Email')+" selected ")],1)]}}],null,false,3316420518),model:{value:(_vm.formData.emails),callback:function ($$v) {_vm.$set(_vm.formData, "emails", $$v)},expression:"formData.emails"}}):_c('div',[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1),_c('AlertMessage',{attrs:{"success":_vm.success,"error":_vm.error}}),_c('BaseFormAction',{staticClass:"mt-2",attrs:{"x-large":false,"loading":_vm.loading,"submit-label":_vm.submitLabel},on:{"click":_vm.onSubmit,"cancel":_vm.onCancel}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }