<template>
  <div>
    <MailingList />
  </div>
</template>

<script>
import MailingList from '../../../components/Admin/MailingList.vue';
export default {
  components: {
    MailingList
  }
};
</script>

<style>
</style>
