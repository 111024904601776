<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit()">
      <v-text-field
        :rules="rules.requiredRule"
        label="List Name"
        v-model="formData.title"
      ></v-text-field>
      <h4 class="text-h6">Select Recipients</h4>
      <SelectList
        search
        sort
        v-if="users"
        ref="emailSelect"
        v-model="formData.emails"
        :items="users"
        itemText="value"
      >
        <template #footer="{ selected }">
          <span class="info--text">
            <v-icon color="info" class="mr-1">mdi-email</v-icon>
            {{ selected }}
            {{ selected > 1 ? 'Emails' : 'Email' }}
            selected
          </span>
        </template>
      </SelectList>
      <div v-else>
        <v-progress-linear indeterminate></v-progress-linear>
      </div>
      <AlertMessage :success="success" :error="error" />
      <BaseFormAction
        class="mt-2"
        :x-large="false"
        :loading="loading"
        :submit-label="submitLabel"
        @click="onSubmit"
        @cancel="onCancel"
      />
    </v-form>
  </div>
</template>

<script>
import SelectList from '../../Core/SelectList.vue';
import ruleMixin from '@/mixins/rules';
import AlertMessage from '../../Core/AlertMessage.vue';
import User from '../../../services/user';
const user = new User();
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    submitLabel: {
      type: String,
      default: 'Add'
    }
  },
  mixins: [ruleMixin],
  components: {
    SelectList,
    AlertMessage
  },
  data() {
    return {
      formData: {
        emails: []
      },
      valid: true,
      success: '',
      error: '',
      loading: false,
      users: null
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.formData = {};
        }
      }
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      user.listUsers().then(res => {
        let userData = [];
        res.forEach(u => {
          const uData = u.data();
          userData.push({
            text: `${uData.first_name || ''} ${uData.last_name || ''}`,
            value: uData.email
          });
        });
        userData = userData.filter(i => i.value);
        this.users = userData;
      });
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.submit();
      }
    },
    reset() {
      this.formData.title = '';
      this.$refs.emailSelect.clear();
      this.$refs.form.resetValidation();
    },
    onCancel() {
      this.cancel();
      this.$emit('cancel');
    }
  }
};
</script>


<style>
</style>
